<template>
  <div>
    <div
      :id="this.data1.id"
      class="graph"
    />
  </div>
</template>
<script>
import Plotly from '../../custom-plotly'

export default {
  props: {
    data1: {
      type: Object,
      required: true,
    },
    onclickFunction: {
      type: Function,
      default: undefined,
      required: false,
    },
    range: {
      type: Array,
      required: false,
      default: function() {
        return []
      },
    },
  },
  data() {
    return {
      primaryColor: 'rgb(14, 74, 125)',
      secondaryColor: 'rgb(232, 119, 34)',
      chartPointer: 'default',
    }
  },
  watch: {
    range: {
      handler: function() {
        /* eslint-disable no-console */
        // console.log("Range Change")
        /* eslint-enable no-console */
        this.adjustRange()
      },
    },

    'data1.changeable': {
      handler: function() {
        /* eslint-disable no-console */
        // console.log("Plot Change")
        /* eslint-enable no-console */
        this.plotIt()
      },
      deep: true,
    },
  },
  mounted: function() {
    window.addEventListener('resize', this.resizeGraphs)
    this.plotIt()
  },
  beforeDestroy: function() {
    window.removeEventListener('resize', this.resizeGraphs)
  },
  methods: {
    hasRange: function() {
      return (this.range !== undefined && this.range.length > 0)
    },

    adjustRange: function() {
      // Adjust the range shown
      const myplot = document.getElementById(this.data1.id)
      let rangeStart = this.data1.changeable.x[0]
      let rangeEnd = this.data1.changeable.x[this.data1.changeable.x.length - 1]

      if (this.hasRange()) {
        rangeStart = parseInt(this.range[0])
        rangeEnd = this.data1.changeable.x[this.data1.changeable.x.length - 1]
        if (this.range.length >= 2) {
          rangeEnd = this.range[1]
        }
      }
      if (typeof rangeStart === 'number' && typeof rangeEnd === 'number') {
        Plotly.relayout(myplot, { 'xaxis.range': [rangeStart - 0.5, rangeEnd + 0.5] })
      }
    },

    plotIt: function() {
      const myplot = document.getElementById(this.data1.id)
      let type = 'bar'

      if (this.data1.type !== undefined) {
        type = this.data1.type
      }

      if (this.data1.changeable.y === undefined) {
        /* eslint-disable no-console */
        console.log('No Y')
        /* eslint-enable no-console */
        return
      }

      let showlegend = Array.isArray(this.data1.changeable.y[0])
      if (this.data1.showlegend !== undefined) {
        showlegend = this.data1.showlegend
      }

      // Range start/end years. For when you want to crop the range
      // let startYear = this.sliderStart
      // let endYear = this.data1.changeable.x[this.data1.changeable.x.length - 1]

      const layout = {
        title: this.data1.changeable.title,
        showlegend: showlegend,
        legend: {
          x: 0,
          y: 1,
          bgcolor: '#FFFFFFA0',
        },
        xaxis: { // Adjustable
          title: this.data1.changeable.axis.x,
          fixedrange: true,
        },
        yaxis: {
          title: this.data1.changeable.axis.y,
          rangemode: 'nonnegative',
          fixedrange: true,
        },
        margin: {
          l: 50,
          r: 20,
        },
      }

      if (this.data1.changeable.barmode === undefined) {
        layout.barmode = 'group'
      } else {
        /* eslint-disable no-console */
        // console.log('Barmode: ' + this.data1.changeable.barmode)
        /* eslint-enable no-console */
        layout.barmode = this.data1.changeable.barmode
      }

      if (this.data1.changeable.x.length < 10) {
        layout.xaxis.autotick = false
      }

      const config = {
        /* Described in Plotly - /src/plot_api/plot_config.js */
        /* showLink: true */ // External Link to plotly's website with the table/chart. Same as the "Edit in Chart Studio" button in the ModeBar
        /* linkText: "Custom showLink text!" */
        scrollZoom: false,
        // image_filename: "something meaningful", // Doesn't work with the javascript
        displaylogo: false, // Hide the plotly logo
        displayModeBar: true,
        modeBarButtonsToRemove: [
          'sendDataToCloud', // Edit on plotly's website
          'select2d',
          'lasso2d', // Not really useful unless using a scatter plot
          'hoverClosestCartesian', // Not that useful, on by default anyway
          'hoverCompareCartesian', // again, not that useful
          'zoom2d',
          'toggleSpikelines',
          'zoom2d',
          'pan2d',
          'zoomIn2d',
          'zoomOut2d',
          'autoScale2d',
        ],
        /* modeBarButton Options
        'select2d',
        'lasso2d',
        'sendDataToCloud',
        'toImage',
        'resetScale2d',
        'hoverClosestCartesian',
        'hoverCompareCartesian'
        */
        // responsive: true,
      }

      const plotdata = [
        {
          x: this.data1.changeable.x,
          y: this.data1.changeable.y,
          type: type,
          label: this.data1.changeable.label,
          marker: { color: this.primaryColor },
        },
      ]

      /* eslint-disable no-console */
      // console.log(plotdata)
      /* eslint-enable no-console */

      if (Array.isArray(this.data1.changeable.y[0])) {
        plotdata[0].y = this.data1.changeable.y[0]
        plotdata[0].name = this.data1.changeable.label[0]
        plotdata.push(
          {
            x: this.data1.changeable.x,
            y: this.data1.changeable.y[1],
            type: type,
            name: this.data1.changeable.label[1],
            marker: { color: this.secondaryColor },
          }
        )
      }
      Plotly.purge(myplot) // Clears the old plot
      Plotly.react(myplot, plotdata, layout, config)

      this.adjustRange()

      if (this.onclickFunction !== undefined) {
        // myplot.on('plotly_click', this.onclickFunction(plotdata))
        myplot.on('plotly_click', this.onclickFunction)
        // this.chartPointer = 'pointer'
      }
    },

    // resizeGraphs: function(){+
    resizeGraphs: function() {
      const allCharts = document.getElementsByClassName('js-plotly-plot')
      for (let i = 0; i < allCharts.length; i++) {
        try {
          Plotly.Plots.resize(allCharts[i])
        } catch (e) {
          /* eslint-disable no-console */
          console.log('Unable to resize \'' + allCharts[i] + '\'')
          /* eslint-enable no-console */
        }
      }
    },
  },
}

</script>
<style>
.js-plotly-plot .plotly .cursor-pointer {
  /* cursor: var(--chart-pointer, default); */
  cursor: default !important;
}
</style>
